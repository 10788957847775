import { makeStyles } from '@material-ui/core/styles';
import BgGrit from '@/shared/images/bg-grit.png';

export const useStyles = makeStyles((theme) => ({
  box: {
    position: 'absolute',
    background: `url(${BgGrit})`,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,

    [theme.breakpoints.down('sm')]: {
      opacity: 0.4
    }
  }
}));
