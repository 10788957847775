export const ROADMAP_PAGE = 'roadmapPage';

export const PAGES = {
  INVEST_WITH_PAYCHECK_PAGE: 'investWithPaycheckPage',
  WHAT_IS_PAYCHECK_PAGE: 'whatIsPaycheckPage',
  WHAT_IS_TOKEN_PAGE: 'whatIsTokenPage',
  FAUCET_PAGE: 'faucetPage',
  SWAP_PAGE: 'swapPage',
  STAKE_PAGE: 'stakePage',
  MARKETPLACE_PAGE: 'marketplacePage',
  ECOSYSTEM_PAGE: 'ecosystemPage',
  ROAD_MAP_PAGE: 'roadmapPage',
  FAQ_PAGE: 'FAQPage',
  TEAM_PAGE: 'teamPage',
  PARTNERS_PAGE: 'partnersPage'
};

export enum HomePageNamesAndHashes {}
