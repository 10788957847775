import { makeStyles } from '@material-ui/core/styles';
import { IScrolledStyle } from '.';

export const useStyles = makeStyles((theme) => ({
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    paddingTop: '50px',
    display: 'flex',
    alignItems: 'center',
    transition: '0.6s all ease-out',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 24,
      zIndex: 2000
    }
  },

  container: {
    flexBasis: '100%'
  },

  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      height: 35
    }
  },
  img: {
    width: 220,
    height: 77,
    [theme.breakpoints.down('sm')]: {
      width: 120,
      height: 42
    }
  },
  linksWrapper: {
    marginRight: '111px',
    width: '900px',
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'space-between',

    [theme.breakpoints.down('md')]: {
      width: 750
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  mobileMenu: {
    display: 'none',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
      paddingRight: 13
    }
  },
  linksMobileWrapper: {
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      top: '50%',
      transform: 'translateY(-50%)',
      marginLeft: 35,
      height: 310,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      zIndex: 3
    }
  },
  scrolled: ({ isOpen }: IScrolledStyle) => ({
    paddingTop: 0,
    background: isOpen ? 'none' : '#090909ed',
    zIndex: 2000
  }),
  paper: {
    width: '100vw',
    overflow: 'hidden !important',
    overflowY: 'hidden',
    backgroundColor: '#0C0C0C !important'
  }
}));
