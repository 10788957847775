import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  page: {
    position: 'relative',
    background: '#0C0C0C',
    transition: '.3s'
  },
  content: {
    paddingTop: '25px'
  }
});
