import { FacebookSvg } from '@/shared/images/svg/facebook';
import { DiscordSvg } from '@/shared/images/svg/discord';
import { TwitterSvg } from '@/shared/images/svg/twitter';
import { TelegramSvg } from '@/shared/images/svg/telegram';
import { MediumSvg } from '@/shared/images/svg/medium';

export const socialLinksConfig = [
  {
    link: 'https://twitter.com/PaycheckLabs',
    Icon: TwitterSvg
  },

  {
    link: 'https://www.facebook.com/PaycheckLabs',
    Icon: FacebookSvg
  },

  {
    link: 'https://discord.gg/tf5nAqydPP',
    Icon: DiscordSvg
  },

  {
    link: 'https://t.me/paycheck',
    Icon: TelegramSvg
  },
  {
    link: 'https://medium.com/@paycheck',
    Icon: MediumSvg
  }
];
