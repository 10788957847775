import React, { ReactElement } from 'react';
import { IContentWrapper, useStyles } from '.';
import clsx from 'clsx';

export const ContentWrapper = ({
  children,
  className: classNameProp
}: IContentWrapper): ReactElement => {
  const classes = useStyles();

  return <div className={clsx([classes.container, classNameProp])}>{children}</div>;
};
