import React from 'react';
import { useStyles } from '@/pages/home';
import StakePage from '../home/main/stake';

export const StakingPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.page}>
      <StakePage id="stakePage" />
    </div>
  );
};
