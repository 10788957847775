import { makeStyles } from '@material-ui/core/styles';
import { ISocialLinks } from './types';

export const useStyles = makeStyles((theme) => ({
  socialLinks: ({ vertical }: ISocialLinks) => ({
    width: 294,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...(vertical
      ? {
          flexDirection: 'column',
          width: 'max-content',
          height: 294
        }
      : {}),
    [theme.breakpoints.down('sm')]: {
      marginRight: 15
    }
  })
}));
