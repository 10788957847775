import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  links: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24px',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    textDecoration: 'none',
    display: 'inline',
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    },

    '&:hover': {
      cursor: 'pointer',
      color: '#08D2CD'
    },

    [theme.breakpoints.down('sm')]: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '26px',
      textTransform: 'uppercase',
      color: '#FFFFFF'
    }
  },

  active: {
    background: 'linear-gradient(280.42deg, #65F4CD 1.7%, #4799E9 83.14%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  }
}));
