import * as React from 'react';

export const MediumSvg = ({
  primaryColor = '#FFFFFF',
  secondaryColor = '#08D2CD',
  size = 24,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="medium"
    {...props}
  >
    <defs>
      <style>
        {`.medium{stroke:${primaryColor};stroke-linejoin="round";transition: .1s;}
                    .medium:hover{stroke:${size === 24 ? secondaryColor : primaryColor};}`}
      </style>
    </defs>
    <g clip-path="url(#clip0_6680_54962)">
      <path d="M22.5721 4.51914L21.1361 5.90479C21.0132 6.00514 20.9181 6.13556 20.8602 6.28356L21.2326 6.42942L20.8601 6.28357C20.8038 6.42753 20.7845 6.58315 20.8038 6.73616V19.2585C20.7845 19.4115 20.8038 19.5671 20.8601 19.711L21.2326 19.5652L20.8602 19.711C20.9178 19.8582 21.0121 19.988 21.134 20.0881L22.5398 21.4777H15.022L16.4989 20.0338L16.4989 20.0338L16.5029 20.0297C16.598 19.9341 16.7018 19.8196 16.7547 19.6541C16.7965 19.5231 16.7961 19.3819 16.7957 19.2741C16.7957 19.2649 16.7957 19.2559 16.7957 19.2472V9.07822L16.0231 8.93261L11.1368 21.4355H10.9948L5.29663 8.91255L4.53255 9.07822V17.6044C4.47924 18.0683 4.63329 18.5351 4.9545 18.8752L7.04919 21.4355H1.51165L3.60585 18.8746C3.76645 18.7033 3.8859 18.4975 3.955 18.273C4.02223 18.0545 4.04003 17.8239 4.00731 17.598V7.76006C4.02313 7.57002 3.99595 7.37874 3.92768 7.20047C3.86051 7.0251 3.75534 6.86692 3.61987 6.73723L1.79217 4.51914H7.43152L12.2997 15.2777L12.6879 16.1354L13.0358 15.2606L17.3086 4.51914H22.5721Z" />
    </g>
    <defs>
      <clipPath id="clip0_6680_54962">
        <rect width="24" height="24" fill="white" transform="translate(0.0742188 0.998047)" />
      </clipPath>
    </defs>
  </svg>
);
