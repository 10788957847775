const sm = 768;
const md = 992;
export const mediaMdWidth = '992px';
export const mediaLgWidth = '1200px';
export const mediaMdHeight = '768px';
export const mediaLgHeight = '1080px';

export const isMoreThanMobileView = window.innerWidth >= sm;

export const isDesktopView = window.innerWidth >= md;

export const isTabletView = window.innerWidth >= sm && window.innerWidth < md;
