import React from 'react';
import { useStyles } from '.';
import MainVideo from '@/shared/videos/Main.mp4';

export const MainAnimation = () => {
  const classes = useStyles();

  return (
    <video className={classes.video} autoPlay loop muted playsInline>
      <source src={MainVideo} />
    </video>
  );
};
