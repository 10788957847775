import { makeStyles } from '@material-ui/core/styles';
import { mediaLgHeight, mediaLgWidth, mediaMdWidth, mediaMdHeight } from '@/shared/constants';

export const useStyles = makeStyles((theme) => ({
  pageWrapper: {
    position: 'relative',
    paddingBottom: 139,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 51,
      paddingTop: 21
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 90,
    paddingBottom: 53,
    [`@media (min-width: ${mediaMdWidth}) and (max-height: ${mediaMdHeight})`]: {
      paddingTop: 50
    },
    [`@media (min-width: ${mediaLgWidth}) and (min-height: ${mediaLgHeight})`]: {
      paddingTop: 120
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      paddingTop: 0,
      paddingBottom: 0,
      alignItems: 'center'
    }
  },
  contentWrapper: {
    position: 'relative'
  },
  grit: {
    zIndex: 1
  },
  textWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    [theme.breakpoints.between('xs', 'md')]: {
      padding: '0 30px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 15px'
    }
  },
  title: {
    maxWidth: 496
  },
  description: {
    marginTop: 50,
    maxWidth: 540,
    [theme.breakpoints.between('xs', 'md')]: {
      maxWidth: 'none',
      marginTop: 24
    }
  }
}));
