import * as React from 'react';

export const FacebookSvg = ({
  primaryColor = '#FFFFFF',
  secondaryColor = '#08D2CD',
  size = 24,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="facebook"
    {...props}
  >
    <defs>
      <style>
        {`.facebook{stroke:${primaryColor};transition: .1s;}
                    .facebook:hover{stroke:${size === 24 ? secondaryColor : primaryColor};}`}
      </style>
    </defs>
    <path d="M12.0008 23.4284C5.68901 23.4284 0.572266 18.3117 0.572266 11.9999C0.572266 5.68803 5.68901 0.571289 12.0008 0.571289C18.3127 0.571289 23.4294 5.68803 23.4294 11.9999C23.4294 18.3117 18.3127 23.4284 12.0008 23.4284ZM12.0008 23.4284V9.71415C12.0008 8.45178 13.0242 7.42843 14.2866 7.42843H16.0008M8.00084 14.2856H16.0008" />
  </svg>
);
