import * as React from 'react';

export const TelegramSvg = ({
  primaryColor = '#FFFFFF',
  secondaryColor = '#08D2CD',
  size = 24,
  ...props
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="telegram"
    {...props}
  >
    <defs>
      <style>
        {`.telegram{stroke:${primaryColor};stroke-linejoin="round";transition: .1s;}
                    .telegram:hover{stroke:${size === 24 ? secondaryColor : primaryColor};}`}
      </style>
    </defs>
    <path d="M23.4294 2.28564L0.572266 10.4489L7.10288 13.7142L16.8988 7.1836L10.3682 15.3469L20.1641 21.8775L23.4294 2.28564Z" />
  </svg>
);
