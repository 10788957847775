export const ALREADY_OPEN_CONNECTION = -32002;
export const ERROR_CHAIN_NOT_ADDED = 4902;

export const decimalToHexString = (num: number): string => {
  let newNum = num;
  if (newNum < 0) {
    newNum = 0xffffffff + newNum + 1;
  }
  return newNum.toString(16).toUpperCase();
};

export enum MetamaskMethods {
  SwitchEthereumChain = 'wallet_switchEthereumChain',
  AddEthereumChain = 'wallet_addEthereumChain',
  RequestAccounts = 'eth_requestAccounts',
  SendTransaction = 'eth_sendTransaction',
  EthSignTypedDataV4 = 'eth_signTypedData_v4',
  EthGetBalance = 'eth_getBalance'
}

export enum MetamaskEvents {
  AccountsChanged = 'accountsChanged',
  ChainChanged = 'chainChanged'
}

export const CHAIN_DATA = {
  '5': {
    chainId: 5,
    shortName: 'goerli',
    chainName: 'Goerli Test Network',
    rpcUrls: ['https://goerli.infura.io/v3/']
  },
  '137': {
    chainId: 137,
    shortName: 'polygon',
    chainName: 'Polygon Mainnet',
    rpcUrls: ['https://polygon-rpc.com']
  }
};
