import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  image: {
    position: 'absolute',
    bottom: 0,
    left: '-200px',
    zIndex: 1,
    pointerEvents: 'none',
    animation: '$shadowMoving 60s ease-in-out infinite'
  },

  '@keyframes shadowMoving': {
    '0%': {
      left: '-200px'
    },

    '50%': {
      left: '70px'
    },

    '100%': {
      left: '-200px'
    }
  }
});
