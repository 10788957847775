import React, { ReactElement, useEffect, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import { useStyles } from '@/pages/home/main';
import InvestWithPaycheckPage from '@/pages/home/main/investWithPaycheck';
import WhatIsPaycheckPage from '@/pages/home/main/whatIsPaycheck';
import SwapPage from '@/pages/home/main/swap';
import StakePage from '@/pages/home/main/stake';
import WhatIsTokenPage from '@/pages/home/main/whatIsToken';
import WhatIsFaucet from '@/pages/home/main/whatIsFaucet';
import { RoadMapPage } from '@/pages/home/main/roadmap';
import { PAGES } from '@/shared/constants';
import { FAQPage } from '@/pages/home/main/questions';
import { EcosystemPage } from '@/pages/home/main/ecosystem';
import { MarketplacePage } from '@/pages/home/main/marketplace';
import { IMain, START_POINT, ANIMATION_TRIGGER } from '.';
import { TeamPage } from '@/pages/home/main/team';
import { PartnersPage } from '@/pages/home/main/partners';

export const Main = inject('activeLink')(
  observer(({ activeLink }: IMain): ReactElement => {
    const changeActiveHashLink = useCallback(() => {
      Object.values(PAGES).forEach((id) => {
        const elem = document.getElementById(id);
        if (elem && activeLink) {
          const bounding = elem.getBoundingClientRect();
          const bottom = bounding.bottom;
          if (
            elem &&
            activeLink &&
            bottom > START_POINT &&
            bottom <= bounding.height * ANIMATION_TRIGGER
          ) {
            activeLink.setActive(`#${id}`);
          } else if (elem && activeLink && bounding.top === START_POINT) {
            activeLink.setActive('');
          }
        }
      });
    }, [activeLink]);

    useEffect(() => {
      window.addEventListener('scroll', changeActiveHashLink);

      return () => window.addEventListener('scroll', changeActiveHashLink);
    }, [changeActiveHashLink]);

    useEffect(() => {
      changeActiveHashLink();
    }, [changeActiveHashLink]);

    const classes = useStyles();

    return (
      <main className={classes.main}>
        <InvestWithPaycheckPage id="investWithPaycheckPage" />
        {/* <WhatIsPaycheckPage id="whatIsPaycheckPage" />
        <WhatIsTokenPage id="whatIsTokenPage" />
        <WhatIsFaucet id="faucetPage" />*/}
        {/* <SwapPage id="swapPage" />
        <StakePage id="stakePage" />
        <MarketplacePage id="marketplacePage" />
        <EcosystemPage id="ecosystemPage" />
        <RoadMapPage id="roadmapPage" />
        <TeamPage id="teamPage" />
        <PartnersPage id="partnersPage" />
        <FAQPage id="FAQPage" /> */}
      </main>
    );
  })
);
