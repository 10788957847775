import clsx from 'clsx';
import React, { FC } from 'react';
import { isDesktopView } from '@/shared/constants';
import { socialLinksConfig, useStyles, ISocialLinks } from '.';

export const SocialLinks: FC<ISocialLinks> = ({ vertical, className: propStyles }) => {
  const classes = useStyles({ vertical });

  return (
    <div className={clsx([classes.socialLinks], [propStyles])}>
      {socialLinksConfig.map(({ link, Icon }) => (
        <a key={link} href={link} target="_blank" rel="noreferrer">
          <Icon size={isDesktopView ? 24 : 16} />
        </a>
      ))}
    </div>
  );
};
