import React, { ReactElement } from 'react';
import clsx from 'clsx';
import { IBaseLayout, useStyles } from '.';
import { PageTitle } from '@/shared/components/pageTitle';
import { PageDescription } from '@/shared/components/pageDescription';
import { ContentWrapper } from '@/shared/components/contentWrapper';

export const BaseLayout = ({
  id,
  title,
  description,
  className: propStyles,
  children
}: IBaseLayout): ReactElement => {
  const classes = useStyles();

  return (
    <div id={id} className={clsx([classes.wrapper, propStyles])}>
      <ContentWrapper>
        {title && <PageTitle value={title} />}
        {description && <PageDescription value={description} />}
        {children}
      </ContentWrapper>
    </div>
  );
};
