import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    height: 15,
    width: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },

  line: {
    height: 3,
    background: '#FFFFFF',
    borderRadius: 10,
    transition: '0.4s',

    '&.open': {
      width: '90%',
      background: 'linear-gradient(280.42deg, #65F4CD 1.7%, #4799E9 83.14%)',
      transition: '0.2s'
    },

    '&.scrolled': {
      background: 'linear-gradient(280.42deg, #65F4CD 1.7%, #4799E9 83.14%)'
    }
  },

  topLine: {
    transition: '0.2s',

    '&.open': {
      transformOrigin: '0% 0%',
      transform: 'rotate(45deg)'
    }
  },

  middleLine: {
    opacity: 1,
    transition: '0.1s',

    '&.open': {
      opacity: 0
    }
  },

  bottomLine: {
    transition: '0.2s',

    '&.open': {
      transformOrigin: '0% 100%',
      transform: 'rotate(-45deg)'
    }
  },

  paper: {
    width: '100vw',
    background: '#0C0C0C'
  }
});
