import {
  googleAnalyticsInitialize,
  googleAnalyticsViewPage,
  googleAnalyticsSubmitEvent
} from './google';
import { EventArgs } from './types';

export const analyticsInitialize = () => {
  googleAnalyticsInitialize();
};

export const analyticsViewPage = (page: string, title: string) => {
  googleAnalyticsViewPage(page, title);
};

export const analyticsSubmitEvent = ({ category, action }: EventArgs) => {
  googleAnalyticsSubmitEvent({ category, action });
};
