import React, { ReactElement } from 'react';
import { Typography } from '@material-ui/core';
import { IPageTitle, useStyles } from '.';

export const PageTitle = ({ value }: IPageTitle): ReactElement => {
  const classes = useStyles();

  return (
    <Typography variant="h1" color="primary" className={classes.title}>
      {value}
    </Typography>
  );
};
