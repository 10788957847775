import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { I18nextProvider } from 'react-i18next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import i18next, { init } from 'i18next';
import { ThemeProvider } from '@material-ui/styles';
import { GlobalStyles } from './globalStyles';
import { Routes } from '@/pages';
import { getCustomTheme } from './theme';
import ActiveLink from '@/shared/components/hashLinks/model';
import commonEn from '@/shared/translations/en/common.json';
import { analyticsInitialize } from '@/utils/analytics';

init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      common: commonEn
    }
  }
});
analyticsInitialize();

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCA_KEY}>
    <Provider activeLink={ActiveLink}>
      <ThemeProvider theme={getCustomTheme()}>
        <I18nextProvider i18n={i18next}>
          <GlobalStyles />
          <Routes />
        </I18nextProvider>
      </ThemeProvider>
    </Provider>
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
);
