import React, { ReactElement } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { IInvestWithPaycheckPage, useStyles } from '.';
import { BaseLayout } from '@/shared/components/baseLayout';
import { TransparentButton } from '@/shared/components/transparentButton';
import { MainAnimation } from './mainAnimation';
import { FilledButton } from '@/shared/components/filledButton';
import { Typography } from '@material-ui/core';
import { SocialLinks } from '@/shared/components/socialLinks';
import { ContentWrapper } from '@/shared/components/contentWrapper';
import { BackgroundGrit } from '@/shared/components/backgroundGrit';
import { metamaskService } from '@/shared/services/metamask';

const DOWNLOAD_METAMASK = 'https://metamask.io/download/';

export const InvestWithPaycheckPage = observer(({ id }: IInvestWithPaycheckPage): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const handleOnClickWhitePaper = () => window.open('/Paycheck_Whitepaper.pdf');

  const handleConnect = () => {
    if (metamaskService.checkIfMetamaskInstalled()) {
      metamaskService.connect();
    } else {
      window.open(DOWNLOAD_METAMASK, '_target');
    }
  };

  return (
    <div className={classes.mainWrapper}>
      <BackgroundGrit className={classes.grit} />
      <div className={classes.contentWrapper}>
        <SocialLinks className={classes.socialLinks} vertical />
        <ContentWrapper>
          <BaseLayout id={id} className={classes.custombaseLayout}>
            <>
              <div className={classes.pageContent}>
                <div>
                  <Typography color="primary" variant="h1" className={classes.text}>
                    {t('invest-paycheck-title')}
                  </Typography>
                  <Typography color="secondary" variant="body1" className={classes.text}>
                    {t('invest-paycheck-description')}
                  </Typography>
                  <div className={classes.container}>
                    <div className={classes.wrapper}></div>
                  </div>
                </div>
                <div>
                  <MainAnimation />
                </div>
              </div>
            </>
          </BaseLayout>
        </ContentWrapper>
      </div>
    </div>
  );
});
