import { makeAutoObservable } from 'mobx';
import { IActiveHash } from '.';

class ActiveHash implements IActiveHash {
  constructor() {
    makeAutoObservable(this);
  }

  active = '';

  setActive(active: string) {
    this.active = active;
  }
}

export default new ActiveHash();
