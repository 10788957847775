import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  image: {
    position: 'absolute',
    top: 240,
    right: '-120px',
    zIndex: 1,
    pointerEvents: 'none',
    animation: '$shadowMoving 50s ease-in-out infinite'
  },

  '@keyframes shadowMoving': {
    '0%': {
      top: '300px'
    },

    '50%': {
      top: '-80px'
    },

    '100%': {
      top: '300px'
    }
  }
});
