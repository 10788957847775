import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  image: {
    position: 'absolute',
    left: 0,
    zIndex: -1,
    pointerEvents: 'none',
    animation: '$shadowMoving 25s ease-in-out infinite'
  },

  '@keyframes shadowMoving': {
    '0%': {
      top: '-300px'
    },

    '50%': {
      top: 150
    },

    '100%': {
      top: '-300px'
    }
  }
});
