import { event, initialize, pageview } from 'react-ga';
import { EventArgs } from './types';

export const googleAnalyticsViewPage = (page: string, title: string) => {
  if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    pageview(page, [], title);
  }
};

export const googleAnalyticsSubmitEvent = ({ category, action }: EventArgs) => {
  if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    event({ category, action });
  }
};

export const googleAnalyticsInitialize = () => {
  const forceDisabled = !!process.env.REACT_APP_FORCE_DISABLE_GOOGLE_ANALYTICS;

  if (forceDisabled) {
    console.error(
      "Can't initialize Google analytics cause it force disabled, check environment configuration"
    );
    return;
  }

  if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
  } else {
    console.error("Can't initialize Google analytics, check environment configuration");
  }
};
