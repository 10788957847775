import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  video: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'inherit'
    }
  }
}));
