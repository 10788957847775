import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  image: {
    position: 'absolute',
    top: '-50px',
    left: '-70px',
    zIndex: 1,
    pointerEvents: 'none',
    animation: '$shadowMoving 50s ease-in-out infinite'
  },

  '@keyframes shadowMoving': {
    '0%': {
      top: '-50px'
    },

    '50%': {
      top: '400px'
    },

    '100%': {
      top: '-50px'
    }
  }
});
