import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    position: 'relative',
    paddingBottom: 138,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 51
    }
  },
  contentWrapper: {
    position: 'relative',
    maxWidth: '1444px',
    margin: '0 auto'
  },
  grit: {
    width: '40%',
    zIndex: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '50%'
    }
  },
  pageContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  socialLinksWrapper: {
    position: 'relative'
  },
  socialLinks: {
    position: 'absolute',
    right: 30,
    top: 73,
    zIndex: 3,
    [theme.breakpoints.down('md')]: {
      top: 110,
      height: 200,
      margin: 0
    },
    [theme.breakpoints.down('sm')]: {
      top: 100,
      height: 190,
      margin: 0
    }
  },
  text: {
    maxWidth: 622,
    marginTop: 50,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 15,
      marginTop: 24,
      maxWidth: 'none',
      marginRight: 35
    }
  },
  custombaseLayout: {
    paddingTop: 140,
    paddingBottom: 52,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 107,
      paddingBottom: 0
    }
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 'max-content',
    marginTop: '88px',
    minWidth: '500px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 300,
      minWidth: 270,
      width: '100%',
      marginTop: '36px',
      marginLeft: 15
    }
  },
  whitePaperWrapper: {}
}));
