import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { HashLink } from 'react-router-hash-link';
import { inject, observer } from 'mobx-react';
import { IHashLinksConfig, useStyles } from './';

export const HashLinks = inject('activeLink')(
  observer(({ config, activeLink, footer, toggleMenu }: IHashLinksConfig) => {
    const classes = useStyles();
    const { t } = useTranslation('common');

    return (
      <Fragment>
        {config.map(({ path, title }, index: number) => (
          <HashLink
            className={clsx(classes.links, {
              [classes.active]: !footer && activeLink?.active === path
            })}
            onClick={toggleMenu}
            smooth
            to={path}
            key={index}
          >
            {t(title)}
          </HashLink>
        ))}
      </Fragment>
    );
  })
);
