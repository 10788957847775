import { makeAutoObservable } from 'mobx';
import {
  IWallet,
  IError,
  ERROR_CHAIN_NOT_ADDED,
  decimalToHexString,
  MetamaskMethods,
  MetamaskEvents,
  CHAIN_DATA
} from './';

class WalletService {
  wallet: IWallet;

  isConnect = false;

  isOpenSwitchNetworkDialog = false;

  constructor(wallet: IWallet) {
    this.wallet = wallet;
  }

  init() {
    this.wallet.init();
  }

  connect() {
    this.wallet.connect();
  }

  checkIfMetamaskInstalled() {
    return this.wallet.checkIfMetamaskInstalled();
  }

  checkNetworkId() {
    return this.wallet.checkNetworkId();
  }

  checkIfAccountIsConnected() {
    this.wallet.checkIfAccountIsConnected();
  }

  switchToActualNetwork() {
    this.wallet.switchToActualNetwork();
  }
}

class MetamaskWallet implements IWallet {
  constructor() {
    makeAutoObservable(this);
  }

  account?: string;

  chainId: string = window.ethereum?.networkVersion;

  init() {
    const { ethereum } = window;
    if (ethereum) {
      this.checkIfAccountIsConnected();

      ethereum.on(MetamaskEvents.AccountsChanged, () => {
        this.checkIfAccountIsConnected();
      });
      ethereum.on(MetamaskEvents.ChainChanged, (chainId: string) => {
        this.chainId = chainId;
      });
    }
  }

  async connect() {
    const { ethereum } = window;
    this.chainId = window.ethereum?.networkVersion;
    const accounts = await ethereum.request({ method: MetamaskMethods.RequestAccounts });
    await this.switchToActualNetwork();
    this.account = accounts[0];
  }

  checkIfMetamaskInstalled = () => !!window.ethereum;

  checkNetworkId = () => window.ethereum?.networkVersion;

  checkIfAccountIsConnected = () => {
    this.account = window?.ethereum?.selectedAddress;
    this.chainId = window.ethereum?.networkVersion;
  };

  switchToActualNetwork() {
    const {
      chainId: goerliChainId,
      chainName,
      rpcUrls
    } = CHAIN_DATA?.[process.env.REACT_APP_CHAIN_ID as keyof typeof CHAIN_DATA];
    const chainId = `0x${decimalToHexString(+goerliChainId)}`;

    return window.ethereum
      .request({
        method: MetamaskMethods.SwitchEthereumChain,
        params: [{ chainId }]
      })
      .catch((error: IError, caught: unknown) => {
        if (error.code === ERROR_CHAIN_NOT_ADDED) {
          return window.ethereum.request({
            method: MetamaskMethods.AddEthereumChain,
            params: [{ chainId, chainName, rpcUrls }]
          });
        } else {
        }
        return caught;
      });
  }
}

export const metamaskService = new WalletService(new MetamaskWallet());
