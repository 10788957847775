import React, { ReactElement } from 'react';
import { Typography } from '@material-ui/core';
import { IPageDescription, useStyles } from '.';

export const PageDescription = ({ value }: IPageDescription): ReactElement => {
  const classes = useStyles();

  return (
    <Typography variant="body1" color="secondary" className={classes.description}>
      {value}
    </Typography>
  );
};
