import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '1444px',
    margin: '0 auto',
    padding: '0 30px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 15px'
    }
  }
}));
