import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    maxWidth: '1445px',
    display: 'flex',
    left: '50%',
    transform: 'translateX(-50%)'
  },

  line: {
    position: 'relative',
    height: '100%',
    width: '1px',
    background: 'rgba(100, 146, 136, 0.7)',
    boxShadow: '0px -2px 12px #639186'
  },

  leftLine: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 15
    }
  },

  centreLine: {
    marginLeft: '32.2714681441%',
    height: '6500px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },

  rightLine: {
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginRight: 15
    }
  },

  innerRectangle: {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
    width: '5px',
    height: '17px',
    background: '#64928852',
    mixBlendMode: 'screen',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.5s'
  },

  innerLight: {
    filter: 'blur(1.2px)',
    borderRadius: '50%',
    width: '1px',
    height: '10px',
    background: '#43E8E1'
  }
}));
