import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

export const getCustomTheme = (primary, secondary) => {
  const theme = responsiveFontSizes(
    createTheme({
      spacing: 4,
      props: {
        MuiButtonBase: {
          disableRipple: true
        }
      },
      palette: {
        primary: {
          main: primary || '#FFFFFF'
        },
        secondary: {
          main: secondary || '#E5E5E5'
        },
        error: {
          main: '#D72A2A'
        }
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 992,
          lg: 1200
        }
      }
    })
  );

  theme.overrides = {
    MuiDialog: {
      paper: {
        borderRadius: 14,
        width: 912,
        height: 481,
        background: '#0C0C0C',
        filter:
          'drop-shadow(1px -10px 25px rgba(71, 153, 233, 0.5)) drop-shadow(-10px 10px 30px rgba(96, 229, 210, 0.5))',
        [theme.breakpoints.down('md')]: {
          width: 708,
          height: 443
        },
        [theme.breakpoints.down('xs')]: {
          width: 288,
          height: 467
        }
      },
      paperWidthSm: {
        minWidth: 288,
        maxWidth: 'none'
      }
    },
    MuiDialogTitle: {
      root: {
        borderBottom: '1px solid transparent',
        borderImageSlice: 1,
        borderImage: 'linear-gradient(280.42deg, #65F4CD 1.7%, #4799E9 83.14%)',
        padding: '43px 100px 40px 64px',
        color: theme.palette.primary.main
      }
    },
    MuiDialogContent: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        padding: '49px 68px',
        [theme.breakpoints.down('md')]: {
          padding: '35px 40px 35px 40px'
        },
        [theme.breakpoints.down('xs')]: {
          padding: '16px 12px'
        }
      }
    },
    MuiDialogActions: {
      root: {
        minHeight: 100
      }
    },
    MuiTypography: {
      h1: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'bold',
        lineHeight: '82px',
        letterSpacing: 0,

        [theme.breakpoints.up('lg')]: {
          fontSize: '72px'
        },

        [theme.breakpoints.up('md')]: {
          fontSize: '72px'
        },

        [theme.breakpoints.down('sm')]: {
          fontWeight: 700,
          fontSize: '32px',
          lineHeight: '42px'
        }
      },
      h2: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 24,
        lineHeight: '33px',
        [theme.breakpoints.up('lg')]: {
          fontSize: '24px'
        }
      },
      h3: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        letterSpacing: 0.2,

        [theme.breakpoints.up('lg')]: {
          fontSize: '18px'
        },

        [theme.breakpoints.up('md')]: {
          fontSize: '18px'
        }
      },
      body1: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: '18px',
        lineHeight: '28px',
        display: 'flex',
        alignItems: 'center',
        letterSpacing: '0.2px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '12px',
          lineHeight: '22px'
        }
      },
      body2: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '28px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '12px',
          lineHeight: '24px'
        }
      }
    },
    MuiFormHelperText: {
      root: {
        position: 'absolute',
        bottom: -25
      }
    },
    MuiTooltip: {
      tooltip: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 16,
        lineHeight: '26px',
        textAlign: 'center',
        color: theme.palette.primary.main,
        backgroundColor: '#0C0C0C',
        boxShadow: '-8px 8px 20px rgba(101, 242, 244, 0.3), 8px 8px 20px rgba(74, 167, 228, 0.3)',
        borderRadius: 14,
        padding: '16px',
        letterSpacing: 0.2,
        maxWidth: 336,
        border: '1px solid transparent',
        backgroundClip: 'padding-box',

        '&:after, &:before': {
          position: 'absolute',
          top: -1,
          left: -1,
          right: -1,
          bottom: -1,
          background: `linear-gradient(280.42deg, #65F4CD 1.7%, #4799E9 83.14%)`,
          content: '""',
          zIndex: -1,
          borderRadius: 14
        }
      },
      tooltipPlacementBottom: {
        margin: '2px 0 0 0 !important'
      },
      arrow: {
        fontSize: 20,

        '&::before': {
          backgroundColor: '#0C0C0C',
          border: '1px solid transparent',
          borderImageSlice: 1,
          borderImageSource: 'linear-gradient(280.42deg, #65F4CD 1.7%, #4799E9 83.14%)'
        }
      }
    },
    MuiTabPanel: {
      root: {
        padding: 0,
        height: '100%'
      }
    },
    MuiTableCell: {
      root: {
        borderBottomWidth: 0,
        padding: '8px 0'
      },
      stickyHeader: {
        backgroundColor: '#121515'
      },
      head: {
        color: theme.palette.primary.main,
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '24px',
        letterSpacing: 0
      },
      body: {
        color: '#95ADAC',
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: 14,
        lineHeight: '24px',
        letterSpacing: 0
      }
    },
    MuiTabs: {
      fixed: {
        width: 'auto'
      },
      root: {
        display: 'block',
        overflow: 'initial',
        '& .MuiTab-wrapper': {
          flexDirection: 'row-reverse'
        }
      },
      flexContainer: {
        borderBottom: '1px solid #EFEFEF',
        '& > button': {
          marginLeft: 0
        }
      },
      indicator: {
        background: 'linear-gradient(280.42deg, #65F4CD 1.7%, #4799E9 83.14%)'
      }
    },
    MuiTab: {
      root: {
        minWidth: '0px !important',
        padding: '0 !important'
      },
      labelIcon: {
        [theme.breakpoints.down('md')]: {
          minHeight: 48
        }
      },
      textColorInherit: {
        textTransform: 'none',
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '28px',
        color: '#999999',
        opacity: 1,
        [theme.breakpoints.down('xs')]: {
          fontSize: 14
        },
        '& > span > div': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '0 !important',
          marginLeft: 10,
          width: 20,
          height: 20,
          background: '#F8F9FD',
          fontWeight: 400,
          fontSize: 14,
          lineHeight: '24px',
          color: theme.palette.primary.main
        }
      },
      selected: {
        fontFamily: 'Nunito',
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '28px',
        color: '#FFFFFF',
        [theme.breakpoints.down('xs')]: {
          fontSize: 14
        },
        '& > span > div': {
          background: 'linear-gradient(97.91deg, #FE82DB 6.1%, #68E4FF 103.66%) !important',
          color: theme.palette.secondary.main
        }
      }
    }
  };

  return theme;
};
