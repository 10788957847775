import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  description: {
    maxWidth: '650px',
    marginTop: '50px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '24px'
    }
  }
}));
