import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { BrowserRouter as Router, Routes as Switch, Route } from 'react-router-dom';
import { metamaskService } from '@/shared/services/metamask';
import { HomePage } from './home';
import { FaucetPage } from './faucet';
import { analyticsViewPage } from '@/utils/analytics';
import { StakingPage } from './stake';

export const Routes = observer(() => {
  useEffect(() => {
    metamaskService.init();
    analyticsViewPage(window.location.pathname + window.location.search, '');
  }, []);

  return (
    <Router>
      <Switch>
        <Route path="/" element={<StakingPage />} />
        <Route path="/stake" element={<StakingPage />} />
      </Switch>
    </Router>
  );
});
