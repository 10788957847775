import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  imageWrapper: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 300
  },

  image: {
    position: 'absolute',
    left: '-600px',
    zIndex: 0,
    pointerEvents: 'none',
    animation: '$shadowMoving 35s ease-in-out infinite',
    [theme.breakpoints.down('sm')]: {
      animation: 'none',
      top: -720
    }
  },

  '@keyframes shadowMoving': {
    '0%': {
      top: '-600px'
    },

    '50%': {
      top: '300px'
    },

    '100%': {
      top: '-600px'
    }
  }
}));
